import errorHandler from './handler/erroHandler';
import axiosInstance from './manager/manager.service';

export const emissionService = {
    create,
    update,
    get,
    getOne,
    del,
    delPodcast,
    addFile,
    addProgramme,
    delProgramme,
    getCalendar,
    updateStatus,

};

async function create(data) {

    try {
        let ret = await axiosInstance.post(`/emissions`, data);

        return ret.data;
    } catch (error) {
        return errorHandler(error);
    }
}

async function get() {

    try {
        let ret = await axiosInstance.get(`/emissions`);

        return ret.data;
    } catch (error) {
        console.log(error);
        return [];
    }

}
async function getCalendar() {

    try {
        let ret = await axiosInstance.get(`/emissions/calendar`);

        return ret.data;
    } catch (error) {
        console.log(error);
        return [];
    }

}

async function getOne(id) {

    try {
        let ret = await axiosInstance.get(`/emissions/${id}`);

        return ret.data;
    } catch (error) {
        console.log(error);
        return [];
    }

}

async function update(data, id) {
    try {
        let ret = await axiosInstance.put(`/emissions/${id}`, data);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}

async function updateStatus(data, id) {
    try {
        let ret = await axiosInstance.put(`/emissions/status/${id}`, data);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}


async function del(id) {
    try {
        let ret = await axiosInstance.delete(`/emissions/${id}`);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}

async function delPodcast(id) {
    try {
        let ret = await axiosInstance.delete(`/emissions/podcast/${id}`);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}

async function delProgramme(id) {
    try {
        let ret = await axiosInstance.delete(`/emissions/programme/${id}`);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}

async function addFile(id, data) {
    try {
        let ret = await axiosInstance.post(`/emissions/podcast/${id}`, data);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}

async function addProgramme(id, data) {
    try {
        let ret = await axiosInstance.post(`/emissions/programme/${id}`, data);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}

