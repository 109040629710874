<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";

import { required } from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import {  categories } from "./data-calendar";
import frLocale from '@fullcalendar/core/locales/fr';
import { emissionService } from "../../../services/emission.service";
import moment from "moment";
/**
 * Calendar component
 */
export default {
  page: {
    title: "Calendar",
    meta: [{ name: "description" }],
  },
  components: { FullCalendar, Layout, PageHeader },
  data() {
    return {
      title: "Programmes",
      items: [
        {
          text: "Radio Shalomat",
        },
        {
          text: "Programmes",
          active: true,
        },
      ],
      // calendarEvents: calendarEvents,
      calendarOptions: {
        headerToolbar: {
          left: "",
          center: "title",
          right: "",
          // right: "timeGridWeek",
        },
        firstDay: 1,
        dayHeaderClassNames: ["bg-info", "text-dark", "p-3", "text-white"],
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
        ],
        eventSources: [

          {
            url: `${process.env.VUE_APP_BASE_URL}/emissions/calendar`,
          }

        ],
        dayHeaderFormat: { weekday: 'long' },
        locale: frLocale,
        initialView: "dayGridWeek",//timeGridDay
        themeSystem: "bootstrap",
        // initialEvents: [],
        editable: false,
        droppable: false,
        eventResizableFromStart: true,
        // dateClick: this.dateClicked,
        eventClick: this.editEvent,
        // eventsSet: this.handleEvents,
        weekends: true,
        selectable: false,
        selectMirror: true,
        dayMaxEvents: false,
        displayEventEnd: true
      },
      currentEvents: [],
      showModal: false,
      eventModal: false,
      categories: categories,
      submitted: false,
      submit: false,
      newEventData: {},
      edit: {},
      deleteId: {},
      event: {
        title: "",
        category: "",
      },
      editevent: {
        editTitle: "",
        editcategory: "",
      },
      emissions: []
    };
  },
  validations: {
    event: {
      title: { required },
      category: { required },
    },
  },
  mounted() {
    },
  methods: {
    async getEmissions() {
      this.emissions = await emissionService.get();
      // let emissionsCalendar = [];
      
      this.emissions.forEach(e => {
        e.programmes.forEach(p => {
          this.calendarOptions.initialEvents.push({
            id: p.id,
            title: e.titre,
            start: this.getDayEmission(p.weekDay.id, p.debut),
            end: this.getDayEmission(p.weekDay.id, p.debut),
            className: 'bg-warning text-white',
          });
        })
      });
    },

    getDayEmission(day, horaire) {
      let dates = null;
      let hour = horaire.split(':')[0];
      let minute = horaire.split(':')[1];
      switch (day) {
        case 1:
          dates = moment().day(1);
          break;
        case 2:
          dates = moment().day(2);
          break;
        case 3:
          dates = moment().day(3);
          break;
        case 4:
          dates = moment().day(4);
          break;
        case 5:
          dates = moment().day(5);
          break;
        case 6:
          dates = moment().day(6);
          break;
        case 7:
          dates = moment().day(0);
          break;
          
        default:
          break;
      }
       return dates.set({ hour: hour, minute: minute }).toDate().getTime();
    },
    editEvent(event) {
      this.$router.push(`/emissions/${event.event._def.publicId}`)
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="app-calendar">
              <FullCalendar
                ref="fullCalendar"
                :options="calendarOptions"
              ></FullCalendar>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </Layout>
</template>
